import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchProjects, resetProjectsPage, updateFilters, updateQuery } from 'actions/projects'
import ProjectFinderSection from 'components/hub-page/views/sections/project-finder'

const constructArrayForProjectSection = projects => {
  const serializedProjectArray = []

  projects.map((project, index) => {
    const projectObject = {
      id: project.id,
      image: project.imageUrl,
      title: project.name,
      description: project.description,
      permalink: project.permalink,
      state: project.state,
      publishedAt: project.publishedAt,
      platformAnalyticsTagList: project.platformAnalyticsTagList
    }

    serializedProjectArray.push(projectObject)
  })

  return serializedProjectArray
}

const mapStateToProps = (state, ownProps) => ({
  meta: state.hubPageRevision.revision.sections[ownProps.sectionId].meta,
  projects: constructArrayForProjectSection(state.projects.projects),
  theme: state.hubPageRevision.revision.sections[ownProps.sectionId].theme,
  tags: state.projectTags.tags,
  hub: state.hub.hub,
  dataTestId: 'participant-hubs-views-projects-finder-section',
  anonymousToken: state.currentUser.adapter.token
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    resetProjectsPage,
    fetchProjects,
    updateFilters,
    updateQuery
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(ProjectFinderSection)
