import React, { useEffect, memo, useState } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Query from 'lib/prepare-query';
import Project from 'api/project';
import Cookies from 'js-cookie';

const ProjectsSection = memo(
  ({
    meta,
    sectionId,
    theme,
    isInsideAdminView,
    hub,
    dataTestId,
    anonymousToken,
  }) => {
    const [projectsForThisSection, setProjectsForThisSection] = useState([]);

    useEffect(() => {
      const fetchTokenAndProjects = async () => {
        const host =
          process.env.NEXT_PUBLIC_DEV_API_ENDPOINT || window.location.origin;

        const token = anonymousToken || Cookies.get('unexpected_thunder');;

        const headers = token
          ? {
              'x-api-token': `Bearer ${token}`,
            }
          : {};

        Project.configureAdapter({
          fetch,
          namespace: '/api/v2',
          host,
          headers,
        });

        await fetchProjectsForThisSection();
      };

      if (meta.autoAddProjects) {
        fetchTokenAndProjects();
      }
    }, [meta]);

    /**
     * Fetch all the projects for this section using the project filters
     * and queries. Then, iterate over the fetched projects and show those
     * projects.
     */
    const fetchProjectsForThisSection = async () => {
      const filters = {
        sort: {
          [meta.autoAddProjectsOrder === 'datePublished'
            ? 'published_at'
            : meta.autoAddProjectsOrder]:
            meta.autoAddProjectsOrder === 'name' ? 'asc' : 'desc',
        },
        state:
          meta.autoAddProjectsStatus === 'all'
            ? ['published', 'archived']
            : meta.autoAddProjectsStatus,
        tag_ids: meta.autoAddProjectsTags,
        visibility_mode:
          hub.visibilityMode === 'Public'
            ? meta.autoAddProjects
              ? 'Public'
              : ''
            : '',
        parent_id: hub.id,
      };

      const query = {
        page: 1,
        per_page: meta.autoAddProjectsLimit,
        filterable: true,
        fields: {
          project: [
            'name',
            'image_url',
            'banner_url',
            'description',
            'permalink',
            'state',
            'published_at',
          ],
        },
      };

      /**
       * We can't use the Projects reducer here as there is only one projects reducer
       * but there can be more than one dynamic projects sections.
       *
       * As a result, we need to fetch the data manually and pass it to each dynamic
       * projects section.
       */

      const queryObject = new Query(query, filters);
      const response = await Project.query(queryObject);

      setProjectsForThisSection(
        response.map((project) => ({
          id: project.id,
          ...project.attributes(),
        })),
      );
    };

    const ProjectsLayout = dynamic(() =>
      import(
        /* webpackChunkName: 'ProjectsSectionLayout' */ `../../../../../containers/hub-page/views/sections/projects/layouts/${theme.layout}`
      ),
    );

    return (
      <ProjectsLayout
        sectionId={sectionId}
        projects={projectsForThisSection}
        autoAddProjects={meta.autoAddProjects}
        isInsideAdminView={isInsideAdminView}
        data-testid={dataTestId}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      (prevProps.theme && prevProps.theme.layout) ===
        (nextProps.theme && nextProps.theme.layout) &&
      prevProps.meta === nextProps.meta
    );
  },
);

ProjectsSection.propTypes = {
  meta: PropTypes.object,
  hub: PropTypes.object,
  sectionId: PropTypes.number,
  theme: PropTypes.object,
  isInsideAdminView: PropTypes.bool,
  dataTestId: PropTypes.string.isRequired,
};

export default ProjectsSection;
